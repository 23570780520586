import Vue from 'vue';

export default {
  setUser(state, payload) {
    state.user = payload;
  }, 
  setToken(state, payload){
  	localStorage.setItem('maishoreca_token', payload);
  	state.token = payload;
  },  
};