<template>
    <div class="content-menu d-lg-none">
        <a @click="openMenuNav(!menuNavOpen)" class="content-menu-item btn-menu">
        <span></span>
        <p>Menu</p>
        </a>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters('App', ['menuNavOpen']),
    },
    methods: {
        ...mapActions('App', ['openMenuNav'])
    }
}
</script>