<template>
    <div class="modal modal-default fade in" tabindex="-1" role="dialog" id="modal-leads">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <button class="modal-btn-close" @click="$root.closeModal('modal-leads')">
                    X
                </button>
                <div class="row">
                    <div class="col-12">
                        <div class="modal-header">
                            <h2 class="modal-title">Nova oportunidade</h2>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="modal-body-itens">
                        <form ref="formLead" @submit.prevent="onLead" class="modal-body-itens-form">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Campanha</label>
                                        <div class="content-input-select">
                                            <select name="campaign_id" required>
                                                <option value="">Selecione a campanha</option>
                                                <option v-for="campaign in campaigns" 
                                                    :key="campaign.id"
                                                    :value="campaign.id">{{ campaign.name }}</option>                                                
                                            </select>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11"><path d="M6.5,0,13,11H0Z" transform="translate(13 11) rotate(180)" fill="#f15a29"/></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Nome Cliente</label>
                                        <input type="text" value="" name="name" placeholder="" required/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>NIF</label>
                                        <input type="text" value="" name="nif" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Telemóvel</label>
                                        <input type="text" value="" name="phone" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Email</label>
                                        <input type="email" value="" name="email" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Código Postal</label>
                                        <input type="text" value="" name="postal_code" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Localidade</label>
                                        <input type="text" value="" name="locality" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="content-input">
                                        <label>Observações</label>
                                        <textarea name="message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="recaptcha">
                                        <div class="g-recaptcha" :data-sitekey="recaptchaKey"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-body-itens-form-btns">
                                        <button type="submit">Submeter Lead</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
    data(){
        return{
            campaigns : [],
        }
    },
    computed:{
        ...mapGetters('App', ['recaptchaKey']),
    },
    methods:{
        ...mapActions('App', ['loading']), 
        async listCampaigns(){
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/list-all',  
                params: {
                    is_active: 1
                }              
            }).then(response => response.data.data)
            .then((data) => {                
                this.campaigns = data;                
            })            
        },    
        async onLead(el){            
            let formData = new FormData(this.$refs.formLead)

            this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/lead',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });
                this.$refs.formLead.reset();
                window.grecaptcha.reset();
                this.$root.closeModal("modal-register")
            })
            .catch((error) => {
                this.loading(false);
                window.grecaptcha.reset();
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
        }
    },
    async mounted(){        
        await this.listCampaigns();
    }
}
</script>