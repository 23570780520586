<template>
    <aside class="aside-menu d-lg-none"
     :class="{'aside-menu-active': menuNavOpen}">
         <div class="aside-menu-itens">
            <div class="aside-menu-itens-header">
               <div class="content-menu">
                  <a @click="openMenuNav(!menuNavOpen)" class="content-menu-item btn-menu">
                     <span class="active"></span>
                     <p>Fechar</p>
                  </a>
               </div>
            </div>
            <div class="aside-menu-itens-links">
               <router-link to="/" @click.native="openMenuNav(!menuNavOpen)">
               Ínicio
               </router-link>
               <router-link :to="{name: 'my-leads'}"
               @click.native="openMenuNav(!menuNavOpen)"
                v-content="{section: 'Menu', removeHtml: true}">
                  As suas oportunidades
               </router-link>
               <router-link :to="{name: 'my-campaigns'}" 
               @click.native="openMenuNav(!menuNavOpen)"
               v-content="{section: 'Menu', removeHtml: true}">
                  As suas campanhas
               </router-link>               
               <a class="btn-new-lead" @click="createLead()" 
                  v-content="{section: 'Menu', removeHtml: true}">
               Nova oportunidade
               </a>
               <div v-if="Object.keys(user).length" class="content-user">
                  <router-link :to="{name:'my-account'}"
                  @click.native="openMenuNav(!menuNavOpen)"
                  class="content-user-name px-0">{{ user.name }}</router-link>
                  <a @click="onLogoff" class="content-user-btn-logoff px-0">terminar sessão</a>
               </div>  
               <router-link v-else class="btn-new-lead my-4"
               @click.native="openMenuNav(!menuNavOpen)"
               :to="{name: 'login'}" 
                  v-content="{section: 'Menu', removeHtml: true}">
                  Login
               </router-link>         
            </div> 
            <router-link class="content-logo" to="/">
            <img class="img-fluid" src="@/assets/img/logos/logo-mais-horeca-menu.png" alt="logo mais horeca" title="logo mais horeca"/>
            </router-link>
         </div>
      </aside>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
   computed: {      
      ...mapGetters('User', ['user']),
      ...mapGetters('App', ['menuNavOpen']),
   },   
   methods: {
      ...mapActions('User', ['logoff']),
      ...mapActions('App', ['openMenuNav']),
      createLead(){
         if(Object.keys(this.user).length == 0){
            this.notify({
               type: "warn",
               text: "É necessário aceder para registar Leads",
            })
            return false;
         }
         this.openMenuNav(!this.menuNavOpen);
         this.$root.openModal('modal-leads');
      },
      onLogoff(){
         this.logoff();
         document.location.href = "/";
      }
   }
}
</script>