<template>
  <div id="app" @scroll="handleScroll()">
    <loading :active.sync="loading"
        :is-full-page="true"></loading>

    <HeaderApp 
      v-if="$route.name != 'login' && $route.name != 'dev'"
      :isScrolled="isScrolled" 
      :pathName="$route.name"
      :isScrolledMobile="isScrolledMobile" />

    <transition name="fade" mode="out-in">
    	<router-view/>
	  </transition>

    <AsideApp v-if="$route.name != 'dev'" />
    <FooterApp v-if="$route.name != 'dev'" />

    <ModalPolitics />
    <ModalLead v-if="$route.name != 'dev'" />

    <notifications  position="top center"/>

    <div :class="{'backdrop__bar':true, 'd-none': !backdrop}"></div>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {mapGetters, mapActions} from 'vuex';
import HeaderApp from '@/components/Header';
import AsideApp from '@/components/Aside';
import FooterApp from '@/components/Footer';
import ModalPolitics from '@/components/ModalPolitics';
import ModalLead from '@/components/ModalLead';

export default {
  name: 'App',
  data() {
    return {
      isScrolled: false,
      isScrolledMobile: false,
      root: null,
      pathName: null,
    }
  },
  components: {
    Loading,
    HeaderApp,
    FooterApp,
    AsideApp,
    ModalPolitics,
    ModalLead,
  },
  computed: {
    ...mapGetters('App', ['loading', 'backdrop']),
    ...mapGetters('User', ['user']),
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('User', ['getUser']),
    handleScroll(event) {
      if (event.currentTarget.innerWidth > 992) {
        if (event.currentTarget.scrollY > 100){
          this.isScrolled = true;
        } else {
          this.isScrolled = false;
        }
      } else {
        if (event.currentTarget.scrollY > 100){
          this.isScrolledMobile = true;
        } else {
          this.isScrolledMobile = false;
        }
      }
    }
  },
  mounted(){
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    document.head.appendChild(recaptchaScript);

    if(localStorage.getItem('maishoreca_token') && Object.keys(this.user).length == 0){
      this.getUser();
    }
  }
}
</script>
<style>
@import url('~@/assets/css/style.min.css');
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
.notification-content{
  font-size: 18px;
}
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
</style>
