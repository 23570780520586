import Vue from 'vue';
import directives from '@/directives';
import filters from '@/filters';
import Notifications from 'vue-notification';

Vue.use(require('vue-moment'));
Vue.use(directives)
Vue.use(filters);
Vue.use(Notifications);

