import store from '@/store';

export async function protectedPage(to, from, next) {
    try {
        let user = await store.getters['User/user'];
        
        if (localStorage.getItem("maishoreca_token") && Object.keys(user).length == 0) {
            user = await store.dispatch('User/getUser');            
        }
        
        // return;
        if (typeof user != 'undefined' && Object.keys(user).length > 0) {            
            next();
        } else {                    
            next({
                name: "login"
            })
        }
    } catch (e) {                
        next({
            name: "login"
        })
    }
}
