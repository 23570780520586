export default {
  loading: state => state.loading,
  error: state => state.error,
  alert: state => state.alert,
  modalAlert: state => state.modalAlert,
  configs: state => state.configs,
  backdrop: state => state.backdrop,
  menuNavOpen: state => state.menuNavOpen,
  pusher: state => state.pusher,
  recaptchaKey: state => state.recaptchaKey,
};