import Vue from 'vue';
import Vuex from 'vuex';
/**/
import App from './modules/App';
import User from './modules/User';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    App,
    User,    
  }
})