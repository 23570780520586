import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import './plugins';
import axios from "axios";
import mixin from './mixins';

Vue.config.productionTip = false

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('maishoreca_token');
    config.headers.Authorization =  token;    

    return config;
});

new Vue({
	router,
	store,
	mixins: [mixin],
	render: h => h(App),
}).$mount('#app')