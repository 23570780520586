import axios from 'axios';

export default {    
    /**
     * Metodo que define os textos no backend
     * @param {*} el 
     * @param {*} binding  
     */
    bind: function (el, binding) {            
        let params = binding.value ? binding.value : {}; 
        params.content = el.innerHTML.trim();
        (!('type' in params) ? params.type = 'text' : null);
        params.theme = process.env.VUE_APP_THEME;
        params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;

        // console.log(params)

        axios({
            method: "POST",
            url:  '/api/v1/site/get-save-content',
            data: params
        })
        .then(response => response.data)
        .then((data) => {
            if(params.type == 'text'){
                el.innerHTML = data.data;
            }   
        });        
    }
  };

  